<template>
  <div id="classdetail">
    <headerbar leftvisible="true" title="用户协议" />
    <div class="box" style="padding-bottom: 100px">
      <div class="box-body" style="padding-top: 0">
        <div class="logo">
          <img src="@/assets/iotmobile_logo.png" />
        </div>
        <div class="info">
        <!--StartFragment--><div class="Section0"  style="layout-grid:15.6000pt;" ><h4 align=center  style="text-indent:19.5000pt;mso-pagination:widow-orphan;text-align:center;
background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >用户服务协议</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></h4><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >尊敬的用户，欢迎您使用</font><font face="微软雅黑" >“</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.7500pt;
font-size:10.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:53" >&#183;</ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >AirHome</font><font face="微软雅黑" >”网站（网址：www.iotmobile.cn，简称“本网站”）。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >本网站由北京Asstar有限公司（简称</font><font face="微软雅黑" >“Asstar”或“我们”）所有和运营。在登录、使用本网站之前，您应当仔细阅读、充分理解《用户服务协议》（简称“本协议”）的全部内容，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >特别是本协议中以加粗字体显示的内容，您应当重点阅读。如果您不同意本协议或其中任何条款，请您立即停止登录或使用本网站；您开始登录或使用本网站，即表明您已同意本协议内容，本协议即构成对用户和Asstar（合称</font><font face="微软雅黑" >“双方”）有约束力的法律文件。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第一条</font> <font face="微软雅黑" >定义</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.1 本网站：是指由Asstar所有并负责运营的“.AirHome”网站（网址：www.iotmobile.cn）</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.2 所有权以及相关的权利：在本协议中是指：第一，所有权；第二，知识产权，包括但不限于著作权、专利权、商标权、商业秘密等；第三，除上述权利之外的对物、信息及其载体和表现形式的使用、利用、传播、复制、发行、编辑、修改、处分等权利。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.3 用户：是指接受本网站服务的自然人、法人或其他组织。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.4 个人信息：在本协议中，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户身份或者反映用户活动情况的各种信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.5 </font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:54" ><font face="微软雅黑" >基础服务</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >：是指本网站基于互联网方式向用户提供的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:56" ><font face="微软雅黑" >用户登录注册</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:56" ><font face="微软雅黑" >，</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >图文信息查看、社区卡购买、社区付费、社区评论、社区收藏等服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.6 </font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:55" ><font face="微软雅黑" >视频服务</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >：是指Asstar向用户提供的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:57" ><font face="微软雅黑" >视频</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >数据传送、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:58" ><font face="微软雅黑" >视频</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-10T17:47" ><font face="微软雅黑" >在线</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:Air桀"  datetime="2021-12-06T14:58" ><font face="微软雅黑" >播放</font></ins></span></span><span class="15"  style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;
mso-font-kerning:1.0000pt;" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-10T17:46" ><font face="宋体" >等</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >1.7 平台内经营者：是指除Asstar以外的、通过本网站销售商品或者提供服务的第三方经营者。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第二条</font> <font face="微软雅黑" >网络服务内容</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >2.1 本网站向用户提供的网络服务包括：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(1)用户注册、登录、安全退出、基本资料修改、历史订单查询等服务；</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(2)社区收藏、社区购买、社区评论、社区付费等服务；</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(3)社区卡交易服务；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-10T15:42" ><o:p></o:p></ins></span></span></b></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-10T15:44" ><font face="微软雅黑" >（</font><font face="微软雅黑" >4）视频服务。</font></ins></span></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >2.3 本网站仅提供网络服务，与社区评论有关的评论信息员由用户自行承担舆论风险。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >2.4 用户使用本网站提供的具体服务时，应当满足</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:00" ><font face="微软雅黑" >本网站</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >相应的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:00" ><font face="微软雅黑" >要求和</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >条件、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:58" ><font face="微软雅黑" >并开通</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >相应的服务使用权限。用户不满足使用服务的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:00" ><font face="微软雅黑" >要求和</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >条件、不具备服务使用权限的，无法使用相应服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >2.5 用户使用本网站提供的基础服务的，应当同时遵守用户与Asstar及/或关联公司另行签订的相应服务协议。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第三条</font> <font face="微软雅黑" >用户账号管理</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.1 用户确认，用户注册、登录以及实际使用本网站提供的服务时，应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。如用户不具备前述主体资格，</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:53" ><font face="微软雅黑" >需经其监护人同意后，在本</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:54" ><font face="微软雅黑" >网站注册并获取本网站提供的服务。否则，</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >，Asstar有权注销或永久冻结用户的账户。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.2 用户可以使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:22" ><font face="微软雅黑" >本人</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >手机号码作为本网站账号、登录本网站。用户也可以按照本网站要求的程序进行注册，获得在本网站的账号</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:22" ><font face="微软雅黑" >、</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:06" ><font face="微软雅黑" >密码</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >，登录本网站；按照本</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:55" ><font face="微软雅黑" >网站</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >注册程序获得本网站账号</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:23" ><font face="微软雅黑" >、</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:07" ><font face="微软雅黑" >密码</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >的用户，可以在登录后通过</font><font face="微软雅黑" >“我的个人信息”进行注册信息的变更及注销。用户也可以按照本网站要求的程序、使用用户拥有的其他第三方平台账号</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:23" ><font face="微软雅黑" >、</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:09" ><font face="微软雅黑" >密码</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >登录本网站。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.3 用户使用具体服务时，本网站可能会要求用户完成进一步验证流程，用户应当按照相应页面的提示提供信息及资料，并应保证提供的身份信息及资料</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >最新、</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >真实、及时、完整和准确。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >如</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >用户</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >提供的资料不真实、不准确、不完整，</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >本网站</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >有权要求</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >用户</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >补充，如</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >用户</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >不能及时配合，</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >本网站</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >有权中止或终止向</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >用户</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >提供部分或全部服务，就此产生的损失</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:00" ><font face="微软雅黑" >本网站</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T16:59" ><font face="微软雅黑" >不承担任何责任。</font></ins></span></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.4 用户的账号</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:23" ><font face="微软雅黑" >、</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:09" ><font face="微软雅黑" >密码</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:24" ><font face="微软雅黑" >和</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >短信随机验证码是用户在本网站的唯一身份权证。为享受本网站提供的网络服务，用户应当按照对应的服务要求键入正确的用户账号</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:24" ><font face="微软雅黑" >、</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:09" ><font face="微软雅黑" >密码</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >或短信验证码。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.5 用户应妥善保管用户账号</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:21" ><font face="微软雅黑" >、</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:10" ><font face="微软雅黑" >密码</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >和手机短信验证码。用户账号信息不得出借、转让、继承或者赠与。因非本网站原因导致用户账号</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:28" ><font face="微软雅黑" >信息</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:29" ><font face="微软雅黑" >泄露</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >或被他人窃取的，本网站不承担责任。如果用户发现自己</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:32" ><font face="微软雅黑" >账号</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >信息泄</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:32" ><font face="微软雅黑" >漏或被他人非法使用</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:33" ><font face="微软雅黑" >时</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >，请立即联络Asstar。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:33" ><font face="微软雅黑" >Asstar有权在</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:34" ><font face="微软雅黑" >接到用户的通知</font><font face="微软雅黑" >/自行发现用户的账号异常时，采取相应措施避免用户的损失，前述措施包括但不限于暂时冻结用户的账号。因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用，本网站不承担责任。</font></ins></span></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.6通过</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:37" ><font face="微软雅黑" >用户</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:35" ><font face="微软雅黑" >账号、密码或</font></ins></span></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >手机短信验证码登录后使用本网站的行为，均视为用户本人行为，用户应当承担由此导致的相关后果和责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >3.7 用户登录后，可以设置用户在本网站的昵称。用户账号、昵称不得违反法律法规规定、侵犯或涉嫌侵犯他人合法权益</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:40" ><font face="微软雅黑" >。</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >否则，Asstar有权暂停或终止提供本网站的所有服务，并冻结用户账号。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><o:p></o:p></ins></span></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;line-height:150%;" ><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:1.0000pt;" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >3</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:1.0000pt;" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" >.8 </ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:45" ><font face="微软雅黑" >用户</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >可以随时按照</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:45" ><font face="微软雅黑" >本网站</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >规定的流程申请注销</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:46" ><font face="微软雅黑" >其</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >的账号。当</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:46" ><font face="微软雅黑" >用户</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >请求注销账号时，</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:46" ><font face="微软雅黑" >用户</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >的账号应当不存在任何未了结的义务。账号注销后，本协议自动终止，</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:46" ><font face="微软雅黑" >本网站</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >有权选择不可恢复的清除账号内全部数据或保留账号内的相关信息，任何基于已清除的账号内数据而产生的未了结的权利义务与</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:46" ><font face="微软雅黑" >本网站</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><font face="微软雅黑" >无关。</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;line-height:150%;
color:rgb(102,102,102);letter-spacing:0.7500pt;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T17:44" ><o:p></o:p></ins></span></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第四条</font> <font face="微软雅黑" >商品交易服务规则</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >4.1 用户可以使用本网站提供的内容交易服务，可以通过本网站订购Asstar或平台内经营者销售的内容和服务（统称“服务”）。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >4.2 对于平台内经营者销售的内容，本网站依法履行平台责任，同时用户应当遵守与平台内经营者之间的约定，Asstar不参与平台内经营者与用户之间的交易或服务过程，不对平台内经营者的销售行为的合法性、真实性、有效性作任何明示和默示的担保，亦不对由此产生的后果承担法律责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.1 用户使用本协议项下服务，应当遵守《中华人民共和国网络安全法》《中华人民共和国电信条例》《中华人民共和国计算机信息网络国际联网暂行规定》和其他有关法律、法规或相关规定、以及本协议的约定，不得存在任何违法违规行为，不得侵犯Asstar以及任何第三方的合法权益。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.2 在遵守本协议的前提下，用户有权使用本网站提供的网络服务。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.3 本网站所提供的网络服务需要获得用户授权终端设备的部分信息方可正常使用；本网站按照相关法律法规，以最小化权限原则、区分网络服务场景进行授权获取的提示。用户有权不同意，但可能会影响本网站或相应网络服务的正常使用。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.4 用户承诺使用网络服务应当符合国家法律法规的规定，且行为符合下列要求：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(1)不干扰本网站的正常运转，不进行任何破坏或试图破坏网络安全的行为，不进行任何改变或试图改变本网站系统配置或破坏系统安全的行为，不得侵入本网站及国家计算机信息系统；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(2)不对本网站展示或提供的、由Asstar所有的任何数据或信息作商业性利用，包括但不限于在未经Asstar事先书面同意的情况下，以复制、传播等任何方式使用本网站展示或提供的、由Asstar所有的资料、信息、数据等；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(3)不模仿、修改、翻译、改编、出借、出售、转许可、传播或转让本网站提供的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:22" ><font face="微软雅黑" >内容和</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >服务，也不得逆向工程、反汇编、反编译、分解拆卸或试图以其他方式</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:23" ><font face="微软雅黑" >获取</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >本网站提供的服务的源代码；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(4)不发送、上传和储存带有病毒的、蠕虫的、木马和其他有害内容的计算机代码、文件、脚本和程序；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(5)遵守所有使用网络服务的网络协议、规定、程序和惯例；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(6)不从事其他违反法律法规规定或双方约定的行为。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.5 用户不得通过本网站制作、复制、发布、传播含有下列内容的信息：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >1）</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:24" ><font face="微软雅黑" >违反</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >宪法所确定的基本原则的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >3）损害国家荣誉和利益的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >4）煽动民族仇恨、民族歧视，破坏民族团结的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >5）破坏国家宗教政策，宣扬邪教和封建迷信的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >6）散布谣言，扰乱社会秩序，破坏社会稳定的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >8）侮辱或者诽谤他人，侵害他人合法权益的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >9）含有法律、行政法规禁止的其他内容的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.6 若用户存在违反法律规定或本协议约定的任一情形，本网站有权按相关规定暂停或终止提供服务，保存有关记录，并向政府主管部门报告，由此引起的一切后果和责任由用户负责。同时，Asstar有权终止本协议，并不承担任何责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >5.7 用户违反本协议使用网络服务导致Asstar或第三人</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:25" ><font face="微软雅黑" >遭受</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >损失的，应当承担相应法律责任、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:25" ><font face="微软雅黑" >并</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >赔偿</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:25" ><font face="微软雅黑" >由此给</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >Asstar或第三人</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:26" ><font face="微软雅黑" >造成</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:25" ><font face="微软雅黑" >全部</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >损失。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第六条</font> <font face="微软雅黑" >本网站的权利和义务</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >6.1 任何本网站所发布的信息及/或其载体，及/或其表现形式，及/或服务，除以下情况之外，其所有权及相关的权利均独占性地属于Asstar及/或关联公司所有：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(1)依据相关法律规定该信息及/或载体，及/或服务，不享有所有权以及与此相关的权利的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(2)依据Asstar和其他主体之间的协议、合同、章程，由Asstar和其他主体共</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:28" ><font face="微软雅黑" >同</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >享</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:28" ><font face="微软雅黑" >有</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >，或其他主体单独享有或Asstar转让的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(3)依据Asstar具有法律效力的正式声明而放弃的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >6.2 非经Asstar及/或授权其使用的第三方权利人书面同意，用户不得擅自使用本网站内容，且无权擅自复制、修改这些内容，或创造与内容有关的派生产品。任何对本网站享有的基于该信息及/或其载体，及/或其表现形式，及/或服务的权利的侵犯，均属于对Asstar权利的侵犯。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第七条</font> <font face="微软雅黑" >用户个人信息保护</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >7.1 用户应保证自己在登录使用本网站时用户身份信息的真实性、准确性及完整性，如果相关信息发生变化，用户应及时通知本网站予以变更。用户应当就用户身份信息的不真实、不准确、不完整承担责任。本网站不能也不会对</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:29" ><font face="微软雅黑" >用户</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >个人信息的不真实、不准确，或个人信息未及时更新而引起的任何损失或损害承担责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >7.2 用户未能按照本网站的要求和内容提供本网站要求的必要信息的，本网站有权中止本网站所提供的对应服务，由此所导致的任何已经或可能发生的损失，由用户自行承担，本网站并保留对该用户进行追索的权利。本网站有权对用户的信息及相关操作进行查阅，发现信息或相关操作中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:31" ><font face="微软雅黑" >冻结</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >账户等处理。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >7.3 Asstar非常重视用户个人信息的保护，Asstar通过本网站向用户提供服务时，将按照相关法律法规的规定收集、存储、使用、共享、转移、公开披露、保护和管理用户信息和隐私。本网站的隐私政策具体见《用户隐私政策》，用户同意仔细阅读并充分理解和接受该隐私政策，并同意该隐私政策作为本协议的重要组成部分。Asstar将在停止运营本网站时，停止继续收集个人信息活动，并通过通知或公告的形式告知用户。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第八条</font> <font face="微软雅黑" >违约责任</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >8.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:37" >1</ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" >&nbsp;<font face="微软雅黑" >除双方另有约定外，用户违反本协议中的义务、承诺、保证等的任意内容，Asstar均有权就其违约情节，尤其是对Asstar或相关第三方造成的损失，随时采取以下措施中的一种或多种：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >1）要求用户立即更换、修改相应内容；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >2）限制、中止用户使用相应服务；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >3）终止用户使用全部服务，终止本协议；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >（</font><font face="微软雅黑" >4）依法追究用户的法律责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >8.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:37" >2</ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" >&nbsp;<font face="微软雅黑" >如用户因违反有关法律法规或者本协议，使Asstar遭受任何损失，受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，用户应对Asstar或相关第三方的实际损失进行全额赔偿（包括合理的律师费用等必要费用）。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第九条</font> <font face="微软雅黑" >责任限制</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >9.1 本网站不就通信系统或互联网的中断或无法运作、技术故障、计算机错误或终端病毒、信息损坏或丢失或其它在本网站合理控制范围之外的原因而产生的其他任何性质的破坏而向用户或任何第三方承担赔偿责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >9.2 本网站需要定期或不定期地对相关的信息网络系统、设备进行检修、维护或升级，如因此类情况而造成网络服务在合理时间内的中断，本网站无需为此承担任何责任，但本网站将尽可能事先进行通知。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >9.3 Asstar将致力于提供快捷、准确的网络服务；但因网络环境、信息交互时延等客观因素，Asstar不保证用户在使用本网站时在操作上不会中断或没有错误，不保证能及时纠正本网站所有缺陷，不保证本网站能满足用户的所有要求，亦不保证用户能通过本网站订购所有Asstar的业务和产品，对网络服务的及时性、安全性、准确性也都不作担保。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >9.4 用户通过本网站进行交易、获取有偿服务而发生的所有税费及有关费用，均由用户承担。Asstar提醒用户应该通过自己的谨慎判断确定相关服务及陈列商品及相关信息的准确新、真实性、合法性和有效性。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第十条</font> <font face="微软雅黑" >网络服务的停止</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >10.1 在以下情形下，本网站有权停止对用户提供网络服务，且无需通知用户或取得用户同意:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(1)如用户是通过手机号码及服务密码/或短信验证码登陆的，当该用户所对应的手机号码停机或销号后；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(2)用户违反本协议规定使用网络服务；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(3)用户提供的身份信息等资料不真实；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >(4)用户账户被</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:38" ><font face="微软雅黑" >注销</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >10.2 用户不同意本协议的内容及Asstar对本协议的后续修改，应当停止使用本网站的网络服务。用户如对本网站的服务不满意，可以停止使用本网站的网络服务，或通知本网站停止对该用户的服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >10.3、用户服务停止后，本网站没有义务向该用户或第三方传送任何未处理的信息或未完成的服务，亦无需对该用户或第三方负责。但本协议的终止并不意味着终止前所发生的未完成用户指令的撤销，也不能消除因终止前的交易所带来的法律后果。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第十一条</font> <font face="微软雅黑" >其他网络应用或服务提供</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >11.1 从本网站链接至Asstar以外的应用或服务：某些情况下，本网站会提供跳转至其它应用或服务。此应用或服务将会引导用户到第三方发行或经营的应用或服务界面，该第三方可能并非Asstar的合作机构或与Asstar有任何联系。Asstar将该应用或服务列入本网站内，仅为方便用户的使用需求快捷满足。除非Asstar已经明确声明与该第三方有合作关系，否则，提供应用或服务至此第三方界面，并不视为Asstar同意、推荐、认可、保证或推介任何第三方或在第三方网站上所提供的任何服务、产品，亦不可视为Asstar与该第三方及其网站有任何形式的合作。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >11.2 从本</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><span class="msoIns" ><ins cite="mailto:贾 洪香"  datetime="2021-12-01T18:39" ><font face="微软雅黑" >网</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >站链接至Asstar其它应用或服务：本网站亦包含链接至Asstar其它应用或服务，以方便用户的使用。Asstar其他应用或服务各自制定使用条款，条款可能互有差异，用户应先仔细查阅适用的使用条款，然后方可使用相关的应用或服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第十二条</font> <font face="微软雅黑" >通知</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >12.1 本网站对于用户的所有通知均可通过用户预留的任一联系方式（包括但不限于电子邮件、手机号码）或者本网站公告的方式送达。该等通知于发送之日视为已送达用户。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第十三条</font> <font face="微软雅黑" >协议范围及协议变更</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >13.1 本协议包括《用户服务协议》正文以及所有本网站已经发布的或将来可能发布的各类规则、通知、公告等（统称“规则”）。所有规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >13.2 本协议仅适用于Asstar通过本网站提供的服务，不适用于Asstar通过其他平台（包括但不限于网站、APP等）提供的服务。本网站有权根据法律法规变化和运营需要，不时地制定、修改本协议条款和/或各类规则。本协议及/或各类规则如有任何变更，将以本网站公示方式进行公告，而不再单独通知用户。用户如果不同意相关变更，应立即停止使用本网站提供的网络服务。如果用户继续享用本网站服务，则视为用户对修改后的协议和规则的同意和遵守。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >第十四条</font> <font face="微软雅黑" >法律适用与争议解决</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >14.1 本协议之效力、解释、变更、履行与争议解决均适用中华人民共和国（为本协议之目的，不含香港、澳门、台湾地区）法律法规。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  style="text-indent:19.5000pt;mso-pagination:widow-orphan;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-size:10.5000pt;mso-font-kerning:0.0000pt;
background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >14.2 因双方就本协议的签订、履行或解释发生争议，双方应持平等、友好、争取合理解决问题的态度协商解决；</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(102,102,102);
letter-spacing:0.7500pt;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);" ><font face="微软雅黑" >如协商未成，任何一方均应向Asstar所在地人民法院提起诉讼。</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></div><!--EndFragment-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapAcAtions, mapMutations } from "vuex";
export default {
  name: "agreement",
  components: {},
  computed: {
    ...mapState({
      isLogin: (state) => state.user.isLogin,
      userName: (state) => state.user.userName,
      token: (state) => state.user.token,
      photo: (state) => state.user.photo,
      userID: (state) => state.user.userID,
      nickName: (state) => state.user.nickName,
    }),
  },
  data() {
    return {
      logo: require("@/assets/iotmobile_logo.png"),
    };
  },
  methods: {},
  created: function () {},
  mounted: function () {},
};
</script>
<style lang="css" scoped>
@import url("/style/css/common.css");
.logo {
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: 100%;
}
.info {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  letter-spacing: 1px;
  line-height: 2;
  text-indent: 26px;
}
.box {
  display: flex;
  flex-direction: column;
  background: #fff;
}
.box-body {
  padding: 20px;
}
.box .box-header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
}
.box .box-header .title {
  font-size: 16px;
  font-weight: 700;
  color: #212831;
}
</style>
